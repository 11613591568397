<script>
  export let title = 'Real time chess'
  export let description = ''
  export let imgSrc // = 'https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80'
</script>
<div
  class="w-10/12 md:w-6/12 lg:w-4/12 px-12 md:px-4 mr-auto ml-auto"
>
  <div
    class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-red-400"
  >
    {#if imgSrc }
      <img
        alt="..."
        src={imgSrc}
        class="w-full align-middle rounded-t-lg"
      />
    {/if}


    <blockquote class="relative p-8 mb-4">
      {#if imgSrc }
        <svg
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 583 95"
          class="absolute left-0 w-full block h-95-px -top-94-px"
        >
          <polygon
            points="-30,95 583,95 583,65"
            class="text-red-400 fill-current"
          ></polygon>
        </svg>
      {/if}
      <h3 class="text-xl font-bold text-white">
        {title}
      </h3>
      <p class="text-md font-light mt-2 text-white">
        {description}
      </p>
    </blockquote>
  </div>
</div>
