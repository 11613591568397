<script>
  export let title = ''
  export let description = ''
</script>
<div class="px-4 py-5 flex-auto">
  <div
    class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white"
  >
    <i class="fas fa-sitemap"></i>
  </div>
  <h6 class="text-xl mb-1 font-semibold">
    {title}
  </h6>
  <p class="mb-4 text-blueGray-500">
    {description}
  </p>
</div>
