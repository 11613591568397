<script>
  import RealTimeGameCard from './RealTimeGameCard.svelte'
  import KeyPoint from './KeyPoint.svelte'
</script>

<div class="flex flex-wrap items-center">
  <RealTimeGameCard title="Real Time Chess" description="A new concept of chess that moves at the same time."/>

  <div class="w-full md:w-6/12 px-4">
    <div class="flex flex-wrap">
      <div class="w-full md:w-6/12 px-4">
        <div class="relative flex flex-col mt-4">
          <KeyPoint title="동시에 적용되는 착수" description="착수가 동시에 이루어집니다. 기물이 잡히는 것을 회피하거나, 움직임을 방해할 수 있습니다!"/>
        </div>
        <div class="relative flex flex-col min-w-0">
          <KeyPoint title="고도의 심리전" description="상대와 나의 수가 동시에 적용되고, 이에 따른 규칙이 다르기 때문에 높은 심리전을 즐길 수 있습니다!"/>
        </div>
      </div>
      <div class="w-full md:w-6/12 px-4">
        <div class="relative flex flex-col min-w-0 mt-4">
          <KeyPoint title="자율적인 기물 배치" description="원하는 전략에 따라, 플레이어가 기물 배치를 자유롭게 할 수 있습니다!"/>
        </div>
        <div class="relative flex flex-col min-w-0">
        </div>
      </div>
    </div>
  </div>
</div>
